import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Style, Fill, Stroke, Text } from 'ol/style'
import GeoJSON from 'ol/format/GeoJSON'
import WebGLPointsLayer from 'ol/layer/WebGLPoints'
import Feature from 'ol/Feature'
import toBase64 from './utils/toBase64'

interface Layer {
  id: number
  feature: Array<any>
  properties: any
}

// TODO: Agregar mas informacion sobre el Layer, descripcion, titulo
export default async function vectorLayer(layer: Layer) {
  let features: Feature[] = []
  layer.feature.forEach((f: any) => {
    let feature: any = new GeoJSON({
      featureProjection: 'EPSG:3857',
      dataProjection: 'EPSG:4326'
    }).readFeature(f)
    features.push(feature)
  })
  let vectorSource = new VectorSource({ features: features })
  let fill = new Fill({
    color:
      (layer.properties &&
        layer.properties.fill &&
        layer.properties.fill.color) ||
      'rgba(255,0,0,.5)'
  })
  let stroke = new Stroke({
    color:
      (layer.properties &&
        layer.properties.stroke &&
        layer.properties.stroke.color) ||
      'red',
    width:
      (layer.properties &&
        layer.properties.stroke &&
        layer.properties.stroke.width) ||
      5
  })
  let style = null
  let vectorLayer = null
  if (layer.properties && layer.properties.icon) {
    let imageBase64 = await toBase64(layer.properties.icon.url)
    let img = new Image()
    await new Promise((resolve) => {
      img.onload = resolve
      // @ts-ignore
      img.src = imageBase64
    })
    style = {
      symbol: {
        symbolType: 'image',
        src: imageBase64,
        size: [
          img.width * layer.properties.icon.scale,
          img.height * layer.properties.icon.scale
        ],
        offset: [
          layer.properties.icon.offsetX || 0,
          layer.properties.icon.offsetY || 0
        ]
      }
    }
    // @ts-ignore
    vectorLayer = new WebGLPointsLayer({ source: vectorSource, style: style })
  } else if (layer.properties && layer.properties.radius) {
    style = {
      symbol: {
        symbolType: 'circle',
        size: (layer.properties && layer.properties.radius) || 10,
        color: layer.properties.fill.color,
        offset: [0, 0]
      }
    }
    // @ts-ignore
    vectorLayer = new WebGLPointsLayer({ source: vectorSource, style: style })
  } else {
    style = new Style({ fill, stroke })
    style.setText(
      new Text({
        text: (layer.properties && layer.properties.name) || '',
        font: '20px Aspira Wide Regular',
        stroke: new Stroke({
          color: '#FFFFFF',
          width: 4
        })
      })
    )
    vectorLayer = new VectorLayer({ source: vectorSource, style })
  }
  if (layer.properties.popup) {
    vectorLayer.setProperties({
      popup: layer.properties && layer.properties.popup
    })
  }
  return vectorLayer
}
